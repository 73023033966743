<template>
  <Layout>
    <PageHeader :title="title" class="poppins fw-normal" />
    <div class="addUser">
      <div class="row">
        <div class="col-lg-6 col-12">
          <div class="card">
            <div class="card-body">
              <form ref="form" @submit.prevent="submit">
                <div class="row">
                  <div class="col-md-6">
                    <div class="mb-3">
                      <label class="form-label" for="formrow-Firstname-input"
                        >Category Title</label
                      >
                      <select
                        class="form-control"
                        v-model.trim="$v.form.category_id.$model"
                        @click="allCategory()"
                      >
                        <option disabled v-bind:value="null">
                          Please Select One
                        </option>
                        <option
                          v-for="(category, index) in categories"
                          :key="index"
                          :value="category.id"
                        >
                          {{ category.title }}
                        </option>
                      </select>
                      <div class="error" v-if="$v.form.category_id.$error">
                        Category is required
                      </div>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="mb-3">
                      <label class="form-label" for="formrow-Firstname-input"
                        >Sub Category Title</label
                      >
                      <input
                        type="text"
                        class="form-control"
                        id="formrow-Firstname-input"
                        v-model.trim="$v.form.title.$model"
                        placeholder="Enter Sub Category Name"
                      />
                      <div class="error" v-if="$v.form.title.$error">
                        <div class="error" v-if="$v.form.title.$error">
                          Title is Required!
                        </div>
                        <!-- <div class="error" v-if="!$v.form.title.alpha">
                          Please Enter Letters only
                        </div> -->
                      </div>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="mb-3">
                      <label class="form-label" for="formrow-Lastname-input"
                        >Sub Category Slug</label
                      >
                      <input
                        type="text"
                        class="form-control"
                        id="formrow-Lastname-input"
                        v-model.trim="$v.form.slug.$model"
                        placeholder="Enter Sub Category Slug"
                      />
                      <div v-if="$v.form.slug.$error">
                        <div class="error" v-if="$v.form.slug.$error">
                          Slug is Required!
                        </div>
                        <!-- <div class="error" v-if="!$v.form.slug.alpha">
                          Please Enter Letters only
                        </div> -->
                      </div>
                    </div>
                  </div>
                </div>
                <div class="d-flex flex-wrap gap-3 mt-3">
                  <SubmitButton
                    :processing="processing"
                    type="submit"
                    class="btn btn-primary waves-effect waves-light w-md"
                    text="Add Sub Category"
                  />
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>
<script>
import Layout from "../../../layouts/static/main";
import PageHeader from "@/components/static/page-header";
import { required, alpha } from "vuelidate/lib/validators";
export default {
  name: "add-sub-category",
  components: {
    Layout,
    PageHeader,
  },
  data() {
    return {
      title: "Add Sub Category",
      processing: false,
      categories: null,
      form: {
        category_id: null,
        title: null,
        slug: null,
      },
    };
  },
  validations: {
    form: {
      category_id: {
        required,
      },
      title: {
        required,
        // alpha,
      },
      slug: {
        required,
        // alpha,
      },
    },
  },
  mounted() {
    this.allCategory();
  },
  methods: {
    allCategory() {
      this.$axios
        .get("categories")
        .then((response) => {
          this.categories = response.data.data;
          //   console.log(response.data.data);
        })
        .catch((error) => {
          console.log(error.response);
        });
    },
    submit(e) {
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.submitStatus = "ERROR";
      } else {
        this.processing = true;
        console.log(this.form);
        this.$axios
          .post("sub-categories", this.form)
          .then((response) => {
            this.$router.push({ path: "/sub-category/listing" });
            this.triggerSwal(response.data.message, "success");
            console.log(response.data);
            this.$refs.form.reset();
            this.processing = false;
          })
          .catch((error) => {
            this.handleErrorResponse(error.response, "error");
            console.log(error.response);
            this.processing = false;
          });
      }
    },
  },
};
</script>